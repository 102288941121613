<template>
  <div>
    <base-nav
      v-model="showMenu"
      class="navbar-absolute top-navbar"
      type="white"
      :transparent="true"
    >
      <div
        slot="brand"
        class="pb-2 fixed-top"
        style="background-color: black; opacity: 0.8;display: inline-block"
      >
        <div class="navbar-minimize d-inline">

          <sidebar-toggle-button />
          <div class="minimize-sidebar text-center" style="margin-left: auto;margin-right: auto;margin-top: -35px;">
            <router-link :to="{ name: 'Main' }" class="logo-normal">
              <img v-lazy="logo" height="12px" alt="tulum.party logo" >
              <p style="font-size: 7px;margin-top: -3px">PLAN A | SIDE B</p>
            </router-link>

          </div>
        </div>
        <div
          class="navbar-toggle text-center"
          :class="{ toggled: $sidebar.showSidebar }"
        >
          <button
            type="button"
            class="navbar-toggler"
            aria-label="navigation"
            @click="toggleSidebar"
            style="left: 5px; top: 10px; position: fixed"
          >
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
<!--          <a class="btn btn-sm btn-info" style="position: absolute; left: 35px;top: 10px;">EVENTS<span class="tim-icons icon-minimal-down inline-flex" style="display: inline"></span></a>-->
          <span class="text-center block " style="display: inline-block;margin: 5px">
            <router-link :to="{ name: 'Main' }" class="simple-text logo-normal">
              <img v-lazy="logo" height="12px" alt="tulum.party logo" >
            </router-link>
          </span>
        </div>
      </div>
    </base-nav>
  </div>
</template>
<script>
import { BaseNav } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';

export default {
  components: {
    SidebarToggleButton,
    BaseNav,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
  },
  data() {
    return {
      activeNotifications: false,
      logo: 'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png',
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>
