 q<template>
  <div class="sidebar" >
    <div class="sidebar-wrapper"

         style="background: #1c1c1c;color: #efefef !important;"
         ref="sidebarScrollArea">
<!--      <img src="https://imgix.cosmicjs.com/f492d820-58a0-11ee-8f36-29778be47ba2-telegram-peer-photo-size-1-5109466397708233627-1-0-0.jpg">-->
      <div class="simple-text logo-mini text-center" style="background-color: black">
                <router-link :to="{ path: '/directory' }" class="simple-text logo-normal">

        <img v-lazy="logo" alt="app-logo"  width="45px" height="45px" style="border-radius: 0;margin-top: 10px"/>
                </router-link>
        <br>
        <br>
      </div>
<!--      <div class="logo">-->
<!--        <router-link :to="{ name: 'Main' }" class="simple-text logo-normal">-->
<!--          <span style="font-weight: bold;font-size: 18px;margin-left: 35px">TULUM.PARTY</span>-->

<!--        </router-link>-->
<!--      </div>-->
      <ul class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
        <slot></slot>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'sidebar',
  data: () => {
    return {
      miniLogo: 'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png',
    }
  },
  props: {
    title: {
      type: String,
      default: 'Tulum Party',
      description: 'Sidebar title',
    },
    shortTitle: {
      type: String,
      default: 'TP',
      description: 'Sidebar short title',
    },
    logo: {
      type: String,
      default: 'https://imgix.cosmicjs.com/9c4cf3d0-e0c6-11ef-bee4-3bb1d3c55332-tp-logo-white.png',
      description: 'Sidebar logo',
    },
    backgroundColor: {
      type: String,
      default: 'gray',

      validator: (value) => {
        let acceptedValues = [
          '',
          'gray',
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar background color (gray)',
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these.",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item',
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
