.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.typography-line span {
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 60px;
}

.tim-row h3 {
  margin-top: 0;
}

.offline-doc .page-header:before {
  content: "";
  background: rgba(0, 0, 0, .75);
}

.offline-doc .page-header {
  display: flex;
  align-items: center;
}

.offline-doc .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #fff;
  z-index: 1;
}

#map {
  position: relative;
  width: 100%;
  height: 100vh;
}



header {
  background-color: rgba(33, 33, 33, 0.9);
  color: #ffffff;
  display: block;
  font: 14px/1.3 Arial, sans-serif;
  height: 50px;
  position: relative;
  z-index: 5;
}

header a, a:visited {
  text-decoration: none;
  color: #C5FBEE;
}

.transparent-card {
  background-color: transparent;
}

.palm-header {
  background-image: url( "https://imgix.cosmicjs.com/a2f84220-877e-11eb-8ee1-4b2192b64ce4-stars.png");
  background-repeat: repeat;
  background-size: contain;
  background-position: center;
}



li {
  margin: 10px;
}

.brand-title {
  color: black !important;
  font-size: 1.1rem;
  background-image: linear-gradient(to bottom, #222f2b, #050404)
}

.brand-title-black {
  color: #5cddbe !important;
  font-size: 1.1rem;
  background-color: transparent;
}

img[lazy=loading] {
  opacity: 0.2;
}

img[lazy] {
  opacity: 1;
  transition: opacity 0.5s;
}

.fade-high-light-enter-active,
.fade-high-light-active {
  background: #4b8f78;
  transition: opacity 1.5s ease;
}
.fade-high-light-from,
.fade-high-light-to {
  opacity: 0.5;
  background: silver;
}

.btn-call-for-action {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  color: #FFFFFF !important;
  border: 1px solid #5ed1b4;
}


td {
  font-size: 0.7rem !important;
}

tr {
  font-size: 0.7rem !important;
}

.badge-category {
  margin: 2px;
  font-size: 0.7rem;
  color: silver;
  background-color: #1c1c1c !important;
  padding: 1px;
  font-weight: lighter;

}

.post-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  background-image: linear-gradient(0deg, transparent, black 75%);
}

.post-wrapper iframe {
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
  width: 100%;
  height: 1200px;
}

.image-on-show {
  animation: zoominoutsinglefeatured 2s;
}

@keyframes zoominoutsinglefeatured {
  0% {
    opacity: 0.1;
    transform: scale(1, 0.5);
  }
  50% {
    opacity: 0.4;
    transform: scale(1, 0.7);
  }

  70% {
    opacity: 0.6;
    transform: scale(1, 1);
  }
  100% {
    opacity: 1;

  }
}

@keyframes gradient-anim {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-anim-2 {
  0% {
    background: linear-gradient(0deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  }

  100% {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  }
}

.gradient {
  /*background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);*/
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5);

  /*animation: gradient-anim 15s ease infinite;*/
  color: black;
}

.gradient2 {
  /*animation:  gradient-anim-2  2s ease infinite;;*/
  background: linear-gradient( 0deg, black, #23d5ab, #644BA6, #A64B60);
  color: black;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 44px;
  height: 44px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #4b9b78;
  border-color: #4b9b78 transparent #319a80 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.tulum-party-subtitle-bg {
  background-color: #373737;
  color: #acacac !important;
  font-weight: bold;;
}

dl {
  color: #efefef;
}
dt {
  font-weight: bold;
  margin-bottom: 5px;
}

dd {
  margin-bottom: 7px;
}

.faq-content {
  background-color: #323232;
}

.center-justified {
  text-align: justify;
  margin: 0 auto;
  width: 100%;
  text-align-last: center;
}


