
.card-animation-on-hover{
  cursor: pointer;
  transition: all .3s cubic-bezier(.34,1.61,.7,1);
  &:hover{
    transform: translate3d(0,-5px,0);
    background-color: mediumaquamarine !important;
  }
}

.card-animation-on-hover-no-bg {
  transition: all .3s cubic-bezier(.34,1.61,.7,1);
  &:hover{
    transform: translate3d(0,-5px,0);
  }
}

.animation-on-hover {
  transition: all .3s cubic-bezier(.34,1.61,.7,1);
  &:hover{
    background-color: rgba(255,255,255,0.2) !important;
  }
}
